<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div style="width:500px; display: flex; justify-content: space-between">
          <p class="h_title">POI 번호 : {{ poi_no }}</p>
          <p v-if="role >= 2" class="h_title">1차 검수자(ID) : {{username}}({{userid}})</p>
        </div>
        <div class="fx-5">
          <div class="list_box">
            <div></div>
            <h2 class="h_title">국문</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_ko" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div v-if="is_start('주소', meta.poi_key)" style="width:100%">
                        <textarea class="textareabox" style="height: 72px;" v-model="meta.poi_value"
                          readonly></textarea>
                      </div>
                      <div v-else style="width:100%"><input class="inputbox" v-model="meta.poi_value" readonly>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
          <div class="list_box">
            <h2 class="h_title">
              <template v-if="language == 'en'">영문</template>
              <template v-else-if="language == 'cht'">중국어(번체)</template>
              <template v-else-if="language == 'chs'">중국어(간체)</template>
              <template v-else-if="language == 'ja'">일본어</template>
              <template v-else>번역언어</template>
            </h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="30%" />
                  <col />
                </colgroup>
                <tbody>

                  <tr v-for="meta in metas_info.metas_tr" :key="meta">
                    <th scope="row">{{ meta.poi_key }}</th>
                    <td>
                      <div
                        v-if="is_start('Address', meta.poi_key) || is_start('地址', meta.poi_key) || is_start('住所', meta.poi_key)"
                        style="width:100%">
                        <textarea class="textareabox" :class="language == 'ja' ? 'ja_font' : ''" style="height:72px;"
                          v-model="meta.poi_value"></textarea>
                      </div>
                      <div v-else style="width:100%">
                        <input class="inputbox" :class="language == 'ja' ? 'ja_font' : ''" v-model="meta.poi_value">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">번역내용 관련 질문</th>
                    <td>
                      <input class="inputbox" :class="language == 'ja' ? 'ja_font' : ''" v-model="comment" />
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <template v-if="is_reject == 0">
              <template v-if="checked == 0">
                <div
                  v-if="(role == 1 && translate == 1) || (role == 2 && translate == 2) || (role == 3 && translate == 3)"
                  class="confirm_check mgT10">
                  <a v-if="role == 1" class="btn bg" @click="submit(1)">검수완료</a>
                  <a v-else class="btn bg" @click="submit(1)">검수완료</a>
                  &nbsp;
                  <a v-if="role != 1" class="btn bg" @click="submit(2)">일부 수정</a>
                  &nbsp;
                  <a v-if="role != 3" class="btn bg" @click="submit(3)">재검수 희망</a>
                  &nbsp;
                  <a class="btn bg0" @click="temp_submit()">임시저장</a>
                  &nbsp;
                  <a class="btn bg3" @click="reject()">반려하기</a>
                </div>
              </template>
              <template v-else>
                <span>등록중입니다...</span>
              </template>
            </template>

            <!-- 반려하기 -->
            <div v-if="reject_flag" class="mgT10">
              <p>※ 해당 POI에 문제가 있을 경우에만 반려해 주세요. 반려되면 <span style="color:red">같은 POI 내용을 공유하는 다른 언어에서도 모두 반려</span>됩니다.
              </p>
              <template v-for="rc in reject_cate" :key="rc">
                <input type="radio" :id="rc.id" :name="rc.name" :value="rc.value">
                <label :for="rc.id">{{ rc.value }}</label>&nbsp;&nbsp;
              </template>

              <input type="text" class="inputbox" style="width:350px;" id="reject_etc" placeholder="기타 오류 사유">
              <br>
              <a class="btn bg1 mgT10" @click="reject_submit()">반려 내용 제출하기</a>
            </div>

          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <!-- <a @click="submit()" class="btn bg2">제출</a> -->
          <router-link :to="{ path: '/trans_list/' + language }" class="btn bg2">목록</router-link>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'TransView',
  data() {
    return {
      userid: "",
      username: "",
      metas_info: {},
      translate: "",
      language: "",
      comment: "",
      poi_no: 0,
      confirm: 1,
      role: 0,
      checked: 0,
      is_reject: 0,
      reject_flag: false,
      reject_cate: [
        { id: "reject1", name: "reject_col", value: "인덱스 누락" },
        { id: "reject2", name: "reject_col", value: "한국어 오타" },
        { id: "reject3", name: "reject_col", value: "공란 있음" },
        { id: "reject4", name: "reject_col", value: "중복된 POI" },
        { id: "reject5", name: "reject_col", value: "기타" },
      ]
    };
  },
  components: {
    Header
  },
  methods: {
    is_combine(s, str) { // 단어 포함 체크
      if (str.includes(s)) return true;
      else return false;
    },
    is_start(s, str) { // 단어 시작 체크
      return str.startsWith(s);
      // if (str.startsWith(s)) return true;
      // else return false;
    },
    trans_load() { // 번역 정보 불러오기
      axios({
        url: config.apiurl + "?part=trans&mode=trans_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          tno: this.$route.params.tno,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.metas_info = d;
        this.translate = d.translate;
        this.language = d.language;
        this.comment = d.comment;
        this.role = d.role;
        this.is_reject = d.is_reject;
        this.poi_no = d.poi_no;
        this.username = d.username;
        this.userid = d.userid;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    submit(c) { // 번역완료
      var message = "";
      if (c == 1) {
        if (this.role == 1) {
          message = "번역완료 하시겠습니까?";
        } else {
          message = "검수완료 하시겠습니까?";
        }
      } else if (c == 2) {
        message = "수정 후 검수완료 하시겠습니까?";
      } else {
        message = "상위 관리자로 재검수를 요청하시겠습니까?";
      }
      if (confirm(message)) {
        this.checked = 1;
        axios({
          url: config.apiurl + "?part=trans&mode=trans_create",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            metas: this.metas_info.metas_tr,
            tno: this.$route.params.tno,
            confirm: c,
            comment: this.comment,
            language: this.language
          },
        }).then((response) => {
          console.log(response.data);
          this.$router.push({
            name: "TransList",
            params: { lan: this.language },
          });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    temp_submit() {
      if (confirm("임시저장 하시겠습니까?")) {
        this.checked = 1;
        axios({
          url: config.apiurl + "?part=trans&mode=trans_tempsave",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            metas: this.metas_info.metas_tr,
            tno: this.$route.params.tno,
            comment: this.comment,
            language: this.language,
            role: this.role,
          },
        }).then((response) => {
          console.log(response.data);
          alert("임시저장 되었습니다.");
          this.$router.push({
            name: "TransList",
            params: { lan: this.language },
          });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    reject() { // 반려 토글
      this.reject_flag = !this.reject_flag;
    },
    reject_submit() { // 반려 제출하기
      let rc_check = document.querySelector('input[name="reject_col"]:checked');
      if (rc_check == null) {
        alert("반려 사유를 선택해 주세요.");
      } else {
        let rc = rc_check.value;
        if (rc == '기타') { rc = document.getElementById('reject_etc').value; }

        if (confirm("반려 내용을 제출하시겠습니까? 이 작업은 돌이킬 수 없습니다.")) {
          this.checked = 1;
          axios({
            url: config.apiurl + "?part=trans&mode=trans_reject_submit",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              tno: this.$route.params.tno,
              rc: rc,
            },
          }).then((response) => {
            console.log(response.data);
            alert("반려 되었습니다.");
            this.$router.push({
              name: "TransList",
              params: { lan: this.language },
            });
          }).catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
        }
      }
    }
  },
  mounted: async function () {
    if (this.$route.params.lan == 'en') { await common.check_perm(this.$store.state.token, [411, 412, 413]); } // 영어
    else if (this.$route.params.lan == 'cht') { await common.check_perm(this.$store.state.token, [421, 422, 423]); } // 번체
    else if (this.$route.params.lan == 'chs') { await common.check_perm(this.$store.state.token, [431, 432, 433]); } // 간체
    else if (this.$route.params.lan == 'ja') { await common.check_perm(this.$store.state.token, [441, 442, 443]); } // 일본어
    else {
      alert("해당 언어는 지원하지 않습니다.");
      this.$router.push({ name: "Mypage" });
    }
    this.trans_load();
  }
};
</script>



<style>
.ja_font {
  font-family: "Yu Gothic";
  font-weight: 500;
}
</style>