<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          번역 검수 목록
        </h2>
        <div id="options" style="width:430px; display:flex; justify-content: space-between;">
          <!-- 매니저 이상은 그룹별로 볼 수 있음. -->
          <div v-if="role >= 2 && g_list.length > 0" @change="group_change($event);">
            <select type="selectbox" class="selectbox" style="width:200px;">
              <option value="" selected disabled>그룹 선택</option>
              <option value="">전체</option>
              <option v-for="g in g_list" :key="g.no" :value="g.no">{{ g.group_name }}</option>
            </select>
          </div>
          <div v-else-if="role == 1" class="table_type2" style="width:250px;">
            <table>
              <colgroup>
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th style="text-align:center" scope="col">작업량 / 할당량 ( 진행률[%] )</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align:center">{{ work }} / {{ assign }} ({{ work_rate }}%)</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <label for="need_work">
              내가 작업할 POI만 보기
              <input type="checkbox" name="need_work" v-model="page_option.need_work" @change="need_work_change()" />
            </label>
          </div>
        </div>

        <div class="search_wrapper mgT20">
          <input type="number" class="inputbox" style="width:260px;" v-model="search_poi" placeholder="POI 번호로 검색" />
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
        </div>

        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col />
              <col width="8%" />
              <!-- <col width="8%" /> -->
              <col width="8%" />
              <col width="8%" v-if="role >= 2" />
              <col width="8%" v-if="role >= 2" />
              <col width="8%" v-if="role >= 3" />
              <!-- <col width="8%" /> -->
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">POI 번호</th>
              <th scope="col">이름</th>
              <th scope="col">POI상태</th>
              <th scope="col" v-if="role >= 2">1차 검수자(ID)</th>
              <th scope="col">1차 검수상태</th>
              <!-- <th scope="col">임의검수</th> -->
              <th scope="col" v-if="role >= 2">2차 검수상태</th>
              <th scope="col" v-if="role >= 3">3차 검수상태</th>
              <!-- <th scope="col">관리</th> -->
            </thead>
            <tbody>
              <tr v-if="group_access == 0">
                <td colspan="8">해당 그룹에 접근 권한이 없습니다.</td>
              </tr>
              <tr v-else-if="trans_list.length == 0">
                <td colspan="8">POI가 없습니다.</td>
              </tr>

              <tr v-for="(t, index) in trans_list" :key="t.no" @click="to_view(t.no)" style="cursor:pointer;">
                <!-- <router-link :to="{ path: '/trans_view/' + language + '/' + t.no }"> -->
                <td>{{ trans_numbering(index) }}</td>
                <td>{{ t.poi_no }}</td>
                <td class="txL">{{ t.poi_name }}</td>
                <td>
                  <span v-if="t.is_reject == 1" style="color:red;">오류</span>
                  <span v-else-if="t.is_reject == 0" style="color:blue;">정상</span>
                </td>
                <td v-if="role >= 2">
                  {{ t.username }}({{ t.userid }})
                </td>

                <!-- 0검은색(검수대기), 1파란색(검수완료), 2초록색, 3빨간색 -->
                <td>
                  <span v-if="t.error1 == 1 && role >= 2" class="co_state3">수정 후 완료</span>
                  <span v-else-if="t.error1 == 1 && role == 1" class="co_state1">검수완료</span>
                  <span v-else-if="t.state == 0 && t.tempsave == 1" class="co_state">임시저장</span>
                  <span v-else-if="t.state == 0" class="co_state"></span>
                  <span v-else-if="t.state == 1 || t.state == 3" class="co_state1">검수완료</span>
                  <span v-else-if="t.state == 2" class="co_state2">추가 검수요망</span>
                </td>
                <!-- <td>
                  <span v-if="t.is_rand == 1">O</span>
                </td> -->
                <td v-if="role >= 2">
                  <span v-if="t.error2 == 1" class="co_state3">수정 후 완료</span>
                  <span v-else-if="t.state2 == 3 || (t.state == 1 && t.confirmer2 == 0)" class="co_state1">검수패스</span>
                  <span v-else-if="t.state2 == 0 && t.tempsave == 2" class="co_state">임시저장</span>
                  <span v-else-if="t.state2 == 0" class="co_state"></span>
                  <span v-else-if="t.state2 == 1" class="co_state1">검수완료</span>
                  <span v-else-if="t.state2 == 2" class="co_state2">추가 검수요망</span>
                  <span v-else class="co_state">미검수</span>
                </td>
                <td v-if="role >= 3">
                  <span v-if="t.error2 == 1" class="co_state1">검수완료</span>
                  <span v-else-if="(t.state == 1 && t.confirmer2 == 0) || (t.state2 == 1 && t.confirmer3 == 0)"
                    class="co_state1">검수패스</span>
                  <span v-else-if="t.state3 == 0 && t.tempsave == 3" class="co_state">임시저장</span>
                  <span v-else-if="t.state3 == 0" class="co_state"></span>
                  <span v-else-if="t.state3 == 1" class="co_state1">검수완료</span>
                  <span v-else class="co_state">미검수</span>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <div class="page_box mgT10" style="width:500px;">
          <div style="float:left;">
            page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
            &nbsp;
            <a @click="paging(-1)" class="btn size1">이전</a>
            &nbsp;
            <a @click="paging(1)" class="btn size1">다음</a>
          </div>
          <div style="float:right; ">
            <span>한페이지 게시물 수 : </span>
            <select class="selectbox" v-model="page_option.limit" style="width:80px;" @change="limit_set($event)">
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'TransList',
  data() {
    return {
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      search_poi: "",

      language: this.$route.params.lan,
      page: 1,
      page_total: 1,
      poi_num: 1,
      trans_list: [],
      role: 0,
      page_option: {
        limit: 10,
        gno: "",
        need_work: false,
      },
      group_access: 1, // 그룹 접근권한(매니저)
      work: 0,
      assign: 0,
      work_rate: 0,
    };
  },
  components: {
    Header
  },
  methods: {
    need_work_change() {
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;
      this.trans_list_load();
    },
    to_view(tno) { // view 들어가기
      this.$router.push({
        name: "TransView",
        params: {
          tno: tno,
        }
      });
    },
    limit_set() { // 한페이지 게시물 수
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;
      this.trans_list_load();
    },
    group_change(e) { // 그룹별 보기 selectbox
      // 페이지 초기화
      this.page = 1;
      this.page_total = 1;
      this.poi_num = 1;

      // 그룹 이동
      let gno = e.target.value;
      this.page_option.gno = gno;
      this.trans_list_load();
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.trans_list_load();
    },
    trans_numbering(num) { // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.page_option.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.page_option.limit) - num;
      }
    },
    trans_list_load(poi_no = "") {

      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=trans&mode=trans_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          language: this.language,
          page: this.page,
          limit: this.page_option.limit,
          gno: this.page_option.gno,
          need_work: this.page_option.need_work,
          search: search,
          poi_no: poi_no,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.trans_list = d.list;
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.role = d.role;
        this.g_list = d.g_list;
        this.group_access = d.group_access;
        this.work = d.work;
        this.assign = d.assign;
        this.work_rate = d.work_rate;
        common.set_page_info(this, this.$route.name, this.page, search, this.page_option);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.trans_list_load();
      }, 250);
    },
    doSearch2() {
      this.trans_list_load(this.search_poi);
    },
  },
  mounted: async function () {
    if (this.$route.params.lan == 'en') { await common.check_perm(this.$store.state.token, [411, 412, 413]); } // 영어
    else if (this.$route.params.lan == 'cht') { await common.check_perm(this.$store.state.token, [421, 422, 423]); } // 번체
    else if (this.$route.params.lan == 'chs') { await common.check_perm(this.$store.state.token, [431, 432, 433]); } // 간체
    else if (this.$route.params.lan == 'ja') { await common.check_perm(this.$store.state.token, [441, 442, 443]); } // 일본어
    else {
      alert("해당 언어는 지원하지 않습니다.");
      this.$router.push({ name: "Mypage" });
    }
    await common.set_page(this);
    this.trans_list_load();
  }
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>