<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">참여중인 프로젝트({{ page_num }})</h2>
        <div class="project_list">
          <template v-for="pagegroup in pages" :key="pagegroup">
            <template v-if="pagegroup.active == true">
              <span class="h_title">{{ pagegroup.name }}</span>

              <ul id="pages">
                <template v-for="page in pagegroup.list" :key="page">
                  <li v-if="check_perm(page.permno)">
                    <a :href="page.link"><img :src="page.img" alt="" />
                      <p>
                        {{ page.name }}
                        <i :class="page.icon_class"></i>
                      </p>
                    </a>
                  </li>
                </template>
              </ul>

            </template>
          </template>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
// import axios from "axios";
// import common from "../common/common.js";
// import config from "../common/config.js";

export default {
  name: "Mypage",
  data() {
    return {
      pages: [
        {
          name: "수집",
          active: true,
          list: [
            {
              permno: ["1001"],
              name: "90-1 수집",
              link: "/m_list/1",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["1002"],
              name: "90-2 수집",
              link: "/m_list/2",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
          ],
        },
        {
          name: "정제",
          active: true,
          list: [
            {
              permno: ["4001"],
              name: "90-1 정제",
              link: "/m_edit_list",
              img: "/design/com/img_pd6.png",
              icon_class: "fa-solid fa-pen",
            },
          ],
        },
        {
          name: "번역",
          active: true,
          list: [
            {
              permno: ["2011"],
              name: "영어 번역 할당",
              link: "/m_trans_assign_list/en",
              img: "/design/com/img_pd9.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2012"],
              name: "영어 번역 작업",
              link: "/m_trans_list/en",
              img: "/design/com/img_pd6.png",
              icon_class: "fa-solid fa-pen",
            },
            {
              permno: ["2013"],
              name: "영어 검수 할당",
              link: "/m_trans_confirm_assign_list/en",
              img: "/design/com/img_pd9.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2014"],
              name: "영어 검수 작업",
              link: "/m_trans_confirm_list/en",
              img: "/design/com/img_pd6.png",
              icon_class: "fa-solid fa-pen",
            },
            {
              permno: ["2002"],
              name: "중국어(간체)",
              link: "/m_trans_list/chs",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2003"],
              name: "중국어(번체)",
              link: "/m_trans_list/cht",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2004"],
              name: "러시아어",
              link: "/m_trans_list/de",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2005"],
              name: "베트남어",
              link: "/m_trans_list/de",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["2006"],
              name: "인도어(힌디어)",
              link: "/m_trans_list/de",
              img: "/design/com/img_pd1.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
          ],
        },
        {
          name: "트리플",
          active: true,
          list: [
            {
              permno: ["3011"],
              name: "트리플 할당",
              link: "/m_triple_assign_list",
              img: "/design/com/img_pd9.png",
              icon_class: "fa-solid fa-arrow-up-from-bracket",
            },
            {
              permno: ["3012"],
              name: "트리플 가공",
              link: "/m_triple_list",
              img: "/design/com/img_pd6.png",
              icon_class: "fa-solid fa-pen",
            },
          ],
        },
      ],
      // icon_class: "fa-solid fa-eye",
      myperm: ["1001", "1002",
        "2011", "2012", "2013", "2014",
        "2002", "2003", "2004", "2005", "2006",
        "3011", "3012",
        "4001"
      ], // 내 권한
      page_num: 0, // 내 페이지 갯수
    };
  },
  components: {
    Header,
  },
  methods: {
    check_mypage() {
      this.set_page();
    },
    check_perm(permno) {
      let check = permno.filter((x) => this.myperm.includes(x));
      if (check.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    set_admin() {
      let admin_page = [];
      this.pages.forEach((pagegroup) => {
        pagegroup.list.forEach((page) => {
          page.permno.forEach((permno) => {
            admin_page.push(permno);
          });
        });
      });
      this.myperm = admin_page;
    },
    page_count() {
      var count = 0;
      this.pages.forEach((pagegroup) => {
        pagegroup.list.forEach((page) => {
          let check = page.permno.filter((x) => this.myperm.includes(x));
          if (check.length > 0) {
            count++;
          }
        });
      });
      this.page_num = count;
    },
    set_page() { // 내 권한이 포함되지 않은 영역은 비활성화
      this.pages.forEach((pagegroup) => {
        let check = false;
        pagegroup.list.forEach((page) => {
          page.permno.forEach((permno) => {
            if (this.myperm.indexOf(permno) !== -1) {
              check = true;
            }
          });
        });

        pagegroup.active = check;
      });
    },
  },
  mounted: function () {
    this.set_page();
    this.page_count();
  },
};
</script>
