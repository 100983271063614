<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">권한 목록</h2>
        <div class="search_wrapper">
          <input type="text" class="inputbox" @input="doSearch" placeholder="아이디 및 이름으로 검색하기" />
        </div>
        <!-- table_type -->
        <div class="table_type1 scrollx">
          <table>
            <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="20%" />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">소속</th>
                <th scope="col" v-for="perm_column in perm_columns" :key="perm_column.permno">{{ perm_column.desc }}
                </th>
                <th scope="col">비밀번호 초기화</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="user_list.length == 0">
                <td colspan="20">
                  <span>{{ search_now }}로 검색된 결과가 없습니다</span>
                </td>
              </tr>
              <tr v-for="user in user_list" :key="user">
                <td>{{ user.userno }}</td>
                <td>{{ user.userid }}</td>
                <td>{{ user.username }}</td>
                <td>
                  <select class="selectbox" style="width:124px;" v-model="user.cno"
                    @change="set_class($event, user.userno)">
                    <option v-for="c in class_" :key="c" :value="c.value">
                      {{ c.desc }}
                    </option>
                  </select>
                </td>
                <td v-for="perm_column in perm_columns" :key="perm_column.permno">
                  <input type="checkbox" name="check" v-on:change="set_perm(user.userno, perm_column.permno)"
                    :checked="check_perm(perm_column.permno, user.perm)">
                </td>
                <td>
                  <a class="btn" @click="password_reset(user.userno)">초기화</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>

      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
// import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "Home",
  data: function () {
    return {
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,

      page: 1,
      page_total: 1,
      limit: 20,
      poi_num: 1,

      class_: [],

      perm_columns: [],
      user_list: [
        { userno: "", "username": "", perm: [], "cno": 0 },
      ],
    };
  },
  components: {
    Header
  },
  methods: {
    set_class(e, userno) {
      var cno = e.target.value;
      axios({
        url: config.apiurl + "?part=userperm&mode=perm_set_class",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          userno: userno,
          cno: cno,
        },
      }).then((response) => {
        // console.log(response.data);
        response;
        this.user_list_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    user_list_load() {
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }

      var page = 1;
      if (this.page <= 1) { page = 1; }
      else if (this.page >= this.page_total) { page = this.page_total; }
      else {
        page = this.page;
      }

      axios({
        url: config.apiurl + "?part=userperm&mode=perm_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: search,
          page: page,
          limit: this.limit,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.class_ = d.class;
        let recv_userlist = d.user_list;
        this.user_list = [];
        this.page_total = d.page_total;
        recv_userlist.forEach(thisuser => {
          this.user_list.push(
            { "userno": thisuser.no, "userid": thisuser.userid, "username": thisuser.username, "perm": thisuser.perm_list, "cno": thisuser.cno }
          );
        });
        this.perm_columns = d.perm_list;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },
    check_perm(perm, perm_list) {
      if (perm_list.indexOf(perm.toString()) > -1) {
        return true;
      } else {
        return false;
      }
    },
    set_perm(userno, permno) {
      axios({
        url: config.apiurl + "?part=userperm&mode=perm_set",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          userno: userno,
          permno: permno
        },
      }).then((response) => {
        // console.log(response);
        response;
        this.user_list_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(3)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.user_list_load();
      }, 250);
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.user_list_load();
    },
    password_reset(userno) { // 비밀번호 초기화
      if (userno == 1 || userno == 2) {
        alert("관리자의 비밀번호는 초기화할 수 없습니다!");
      } else {
        if (confirm("비밀번호를 초기화 하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=userperm&mode=perm_password_reset",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              userno: userno,
            },
          }).then((response) => {
            let d = response.data;
            // console.log(d);
            if (d.result == 1) {
              alert("비밀번호가 초기화되었습니다.");
            } else {
              alert("비밀번호 초기화 도중 에러가 발생했습니다. 관리자에게 문의해 주세요. 에러 코드는 [" + d.result + "] 입니다.");
            }
          }).catch(function (error) {
            alert("오류가 발생했습니다...(4)");
            console.log("에러야!");
            console.log(error);
          });
        }
      }
    },
  },
  mounted() {
    this.user_list_load();
  },
};
</script>

<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>